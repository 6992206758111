import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDeletePlace(ctx, id){
            return new Promise((resolve, reject) => {
              axios
                .delete('/place/v1/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
        fetchPropertitypin(ctx, queryParams){

            var data = JSON.stringify(queryParams);
            // ชconsole.log('queryParams',data);
            return new Promise((resolve, reject) => {
                
                axios
                .post('place/v1/filters', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
        fetchBts(ctx, queryParams){

            var data = JSON.stringify(queryParams);
            // ชconsole.log('queryParams',data);
            return new Promise((resolve, reject) => {
              
                axios
                .get('transit/v1/list/bts ')
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        
        },
    }
}