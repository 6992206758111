<template>
<b-card
    no-body
>
    <div class="m-2">
        <b-row>
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                <label>Entries</label>
                <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                />
                <b-button
                    variant="primary"
                    :to="{ name: 'pin-create'}"
                >
                    Add Property Pin
                </b-button>
                </b-col>

                <!-- Search -->
                <b-col
                cols="12"
                md="6"
                >
                <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                    v-model="filterName"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    />
                   
                </div>
            </b-col>
        </b-row>
        
    
    </div>
     <b-table
            ref="refPropertipyListTable"
            :items="fetchPropertypins"
            responsive
            striped
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            >
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                     <b-form-input
                    v-if="field.key == 'id'"
                        v-model="filterId"
                        :placeholder="field.label"
                        />
                    <b-form-input
                    v-if="field.key == 'name'"
                        v-model="filterName"
                        :placeholder="field.label"
                        />
                    <b-form-select
                    v-if="field.key == 'rate'"
                          v-model="filterRate"
                          
                          :options="rateOptions"
                  />
                    
                </td>
            </template>
            <template #cell(image)="data">
             <b-link variant="flat-primary" class="pb0" :to="{ name: 'pin-upload', params: { id: data.item.id } }">
               
                <b-img class="img_property" v-if="data.item.place_image "
                    :src="data.item.place_image"
                    fluid
                />
        <b-img  class="img_property" v-else
                    :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                    fluid
                />
             </b-link>
            </template>
            <template #cell(name)="data">
                <router-link :to="{ name: 'pin-edit', params: { id: data.item.id } }" >{{ data.item.name }}</router-link>
         
            </template>
            <template #cell(rate)="data">
              {{ data.item.rating }}
              
            </template>
            <template #cell(is_active)="data">

              <b-form-checkbox
                  v-if="data.item.is_active == 1"
                  checked="true"
                  class="custom-control-primary"
                  name="is_active"
                  switch
                />
              <b-form-checkbox
                  v-else
                  checked="false"
                  class="custom-control-primary"
                  name="is_active"
                  switch
                />
            </template>
            <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item  :to="{ name: 'pin-edit', params: { id: data.item.id } }" > 
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)">
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>
            
         </b-table>
     <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalPins"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>    
</b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BImg,BFormSelect
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import usePropertypinList from './usePropertypinList'
import propertypinStoreModule from '../propertypinsStoreModule'
import Ripple from 'vue-ripple-directive'
//import propertypinStoreModule from '../propertypinStoreModule'  
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BImg,
        vSelect,
        BFormSelect
        
    },
    setup() {
        const PROPERTYPIN_APP_STORE_MODULE_NAME = 'app-propertypin'
         if (!store.hasModule(PROPERTYPIN_APP_STORE_MODULE_NAME)) store.registerModule(PROPERTYPIN_APP_STORE_MODULE_NAME, propertypinStoreModule)
   
        const imgPath = store.state.appConfig.layout.imgPath
   
        // UnRegister on leave
        onUnmounted(() => {
        
            if (store.hasModule(PROPERTYPIN_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYPIN_APP_STORE_MODULE_NAME)
        })
        const statusOptions = [
            'Unknow',
            'Sale & Rent',
            'Sale',
            'Rent',
            ]
        const {
           fetchPropertypins,  
           fetchBts,    
            tableColumns,
            perPage,
            currentPage,
            totalPins,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refPropertipyListTable,
            statusPin,
            filterId,
            filterName,
            rateOptions,
            filterRate,
            deleteData,
            refetchData,
        } = usePropertypinList()
        return {
            fetchPropertypins,     
            fetchBts, 
            tableColumns,
            perPage,
            currentPage,
            totalPins,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refPropertipyListTable,
            statusPin,
            imgPath,
            filterId,
            filterName,
            statusOptions,
            rateOptions,
            filterRate,
            deleteData,
            refetchData,
        }
    },
  directives: {
    Ripple,
  },
   methods:{
    deleteconfirm(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Property ID.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
    }
  },
  
    //
  
}
</script>
