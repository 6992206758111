import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function usePropertypinList() {
    const toast = useToast()
    const refPropertipyListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: true, tdClass:'width-200'},
        { key: 'image',label: 'Image', sortable: false, tdClass:'width-300 p-1' },
        { key: 'name',label:'Name of property pin', sortable: true },
        { key: 'rate',label:'Rate', sortable: false },
        { key: 'price_sqm',label:'Avg Selling price/sqm', sortable: false },
        { key: 'rent_price_sqm',label:'Avg Rent price/sqm', sortable: false },
        { key: 'zone_name',label:'Zone', sortable: false },
        { key: 'bts_name',label:'BTS', sortable: false },
        { key: 'mrt_name',label:'MRT', sortable: false },
        { key: 'airport_name',label:'Airport Link', sortable: false },
        { key: 'is_active',label:'Active', sortable: false },
        { key: 'actions' },
        
    ]
    const rates = [{}]
    const perPage = ref(10)
    const totalPins = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusPin = ref(null)
    const filterId = ref(null)
    const filterName = ref(null)
    const dataMeta = computed(() => {
    const localItemsCount = refPropertipyListTable.value ? refPropertipyListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPins.value,
        }
    })
    //const rateOptions = ['All','1','2','3','4','5']
    const rateOptions = [
      { value: null, text: 'All' },
      { value: '1', text: '1' },
      { value: '2', text: '2' },
      { value: '3', text: '3' },
      { value: '4', text: '4' },
      { value: '5', text: '5' },
   
    ]
    const filterRate = ref(null)
    const refetchData = () => {
    
        refPropertipyListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery,filterId,filterName,filterRate], () => {
        console.log('change');
        refetchData()
    })
    const fetchBts = (ct, callback)=>{
      console.log('abc');
      store
      .dispatch('app-propertypin/fetchBts')
      .then(response => {
        
       
        console.log('bts',response.data);
      //  const { invoices, total } = response.data
       //callback(properties)
       // callback(invoices)
        //totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      
    }
    const deleteData = (id) => {
      //fetchDeleteProperty
      store.dispatch('app-propertypin/fetchDeletePlace',id).then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete Property",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }
    const fetchPropertypins = (ctx, callback)=>{
      const offset = perPage.value*(currentPage.value-1);
        store
        .dispatch('app-propertypin/fetchPropertitypin', {
          perpage: perPage.value,
          offset:offset,
          keyword:filterName.value,
          byID:filterId.value,
          byRating:filterRate.value
        })
        .then(response => {
          
         
          const properties = response.data.data;
          console.log('pins',response.data);
        //  const { invoices, total } = response.data
         callback(properties)
         totalPins.value = response.data.total
         // callback(invoices)
          //totalInvoices.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching invoices' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        
      }
      return {
        fetchPropertypins,
        fetchBts,
        tableColumns,
        perPage,
        currentPage,
        totalPins,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPropertipyListTable,
        statusPin,
        filterId,
        filterName,
        rateOptions,
        filterRate,
        deleteData,
        refetchData,
      }
      
     
}